<template>
  <div class="bg-white p-2 rounded-md mt-2">
    <DataTable :value="incidentes"
               class="p-datatable-customers p-datatable-sm text-sm"
               showGridlines
               dataKey="id"
               v-model:filters="filters1"
               filterDisplay="menu"
               responsiveLayout="scroll"
               removableSort
    >
      <template #empty>
        No hay datos para mostrar
      </template>

      <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" field="Id" header="Id">
        <template #body="{data}">
          {{ data.id }}
        </template>
      </Column>

      <Column header="Tipo" style="min-width:10rem">
        <template #body="{data}">
          {{ tipo(data) }}
        </template>
      </Column>

      <Column field="observacion" header="Observación" style="min-width:10rem">
        <template #body="{data}">
          {{ data.observacion }}
        </template>
      </Column>

      <Column field="fecha" header="Fecha" style="min-width:10rem">
        <template #body="{data}">
          {{ dayjs(data.fechaNotificacion).format('DD-MM-YYYY HH:mm:ss') }}
        </template>
      </Column>

      <Column
        header="Estado"
        style="max-width:4rem"
      >
        <template #body="{data}">
          <div class="flex justify-center text-xs">
            <button
              v-if="!data.fechaCierre"
              class="font-medium p-1.5 text-white w-3/4 text-center rounded-md"
              v-tooltip.bottom="{value:
                `${dayjs(data.fechaVencimiento) > dayjs() ? 'Vence' : 'Vencido'} ${dayjs(data.fechaVencimiento).fromNow()}
                `
                }"
              :class="indicadorEstadoColor(data.fechaVencimiento)">
              {{ indicadorEstadoNombre(data.fechaVencimiento) }}
            </button>
            <button
              v-else
                class="font-medium p-1.5 text-white w-3/4 text-center rounded-md"
                v-tooltip.bottom="{value: 'Respondido'}"
                :class="indicadorEstadoColor(data.fechaVencimiento, true)">
                {{ indicadorEstadoNombre(data.fechaVencimiento, true) }}
              </button>
          </div>
        </template>
      </Column>

      <Column v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" header="Acciones" style="width: 10rem">
        <template #body="{data}">
          <div class="flex justify-around">
            <Button
              icon="pi pi-file-pdf"
              @click="openMaximizable(data.id)"
              class="p-button-rounded p-button-help"
              v-tooltip.left="'Ver adjuntos'"
            />
            <Button
              icon="pi pi-reply"
              class="p-button-rounded p-button-info"
              v-tooltip.left="'Ver respuestas'"
              @click="openRespuestasIncidentes(data)"
            />
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-warning"
              v-tooltip.left="'Editar'"
              @click="onEditar(data)"
            />
            <Button
              v-if="data.observacionCierre === null"
              icon="pi pi-lock"
              class="p-button-rounded"
              v-tooltip.left="'Cerrar Incidente'"
              @click="onCerrarIncidente(data)"/>
            <Button
              v-else
              icon="pi pi-eye"
              class="p-button-rounded"
              v-tooltip.left="'Ver cierre Incidente'"
              @click="openVerCierreIncidente(data.id)"/>
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator v-model:first="paginator.offset"
               :rows="paginator.limit"
               :totalRecords="incidentes.count"
               :rowsPerPageOptions="[2,10,20,30]"
               @page="onPage($event)"
    />
  </div>

  <Dialog header="Soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '25vw'}" :maximizable="true" :modal="true">
    <div class="flex justify-between" v-for="(soporte, i) in archivos.paths" :key="i">
      <div class="flex items-center justify-center">
        <i class="pi pi-file-pdf mr-2"></i>
        <p class="mb-1">{{ soporte.fileName }}</p>
      </div>
      <div>
        <button type="button" @click="verPdf(soporte.urlPath)"><i class="pi pi-eye mr-2 text-blue-600"></i></button>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Cerrar Incidente" v-model:visible="displayIncidente" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '25vw'}" :modal="true">
    <section class="flex flex-col gap-2">
      <div>
        <label>Observaciones</label>
        <InputText class="w-full" v-model="dataCierre.observacionCierre"></InputText>
      </div>
      <div>
        <label>Fecha de Cierre</label>
        <input type="date" v-model="dataCierre.fechaCierre" class="p-inputtext w-full">
      </div>
      <div class="flex justify-between my-2" v-for="(path, l) in adjuntos" :key="l">
        <div class="flex">
          <i class="pi pi-file-pdf text-red-600 mr-2"></i>
          <p>
            {{ path.fileName }}
          </p>
        </div>
        <div>
          <button @click="eliminarPdf(l)">
            <i class="pi pi-trash text-red-600"></i>
          </button>
        </div>
      </div>
      <button label="Show" icon="pi pi-external-link" @click="openSoportesMaximizable"
              class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
        Agregar nuevo soporte
      </button>
    </section>
    <template class="text-center" #footer>
      <Button label="Cerrar Incidente" class="w-full" @click="cerrarIncidente" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Agregar soportes" v-model:visible="soportesDisplay" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="soportesCloseMaximizable" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Ver Cierre Incidente" v-model:visible="displayVerCierreIncidente"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '25vw'}" :modal="true">
    <section class="flex flex-col gap-2">
      <div>
        <label>Observaciones</label>
        <InputText class="w-full" v-model="dataCierre.observacionCierre"></InputText>
      </div>
      <div>
        <label>Fecha de Cierre</label>
        <input type="date" v-model="dataCierre.fechaCierre" class="p-inputtext w-full">
      </div>
      <div class="flex justify-between" v-for="(path, l) in adjuntos" :key="l">
        <div class="flex">
          <i class="pi pi-file-pdf text-red-600 mr-2"></i>
          <span>
            {{ path.fileName }}
          </span>
        </div>
      </div>
      <button label="Show" icon="pi pi-external-link" @click="openSoportesVerCierre"
              class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
        Agregar nuevo soporte
      </button>
    </section>
    <template class="text-center" #footer>
      <Button label="Cerrar" class="w-full" @click="displayVerCierreIncidente = false" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Agregar soportes" v-model:visible="displaySoportesVerCierre"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeSoportesVerCierre" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Respuestas del Incidente" v-model:visible="displayRespuestasIncidente"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '25vw'}" :modal="true">
    <section class="flex flex-col gap-2">
      <div class="flex justify-end">
        <Button label="Crear respuesta" class="p-button-success" @click="crearRespuesta"/>
      </div>

      <DataTable :value="respuestas" responsiveLayout="scroll">
        <template #empty>
          No hay respuestas por mostrar
        </template>
        <Column field="id" header="Id"></Column>
        <Column field="observacion" header="Observacion"></Column>
        <Column field="createdAt" header="Fecha Creacion"></Column>
        <Column header="Acciones">
          <template #body="{ data }">
            <div class="flex gap-2 justify-center">
              <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info" @click="verRespuesta(data)"></Button>
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
                      @click="verRespuesta(data)"></Button>
            </div>
          </template>
        </Column>
      </DataTable>

    </section>
    <template class="text-center" #footer>
      <Button label="Cerrar" class="w-full" @click="displayRespuestasIncidente = false" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Soportes" v-model:visible="displayRespuestasIncidenteAdjuntos"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '30vw'}" :modal="true">
    <section v-if="soportes.length">
      <div v-for="(soporte, i) in soportes" :key="i">
        <div class="flex justify-between">
          <div>
            <p>
              {{ soporte.fileName }}
            </p>
          </div>
          <div>
            <button @click="verPdf(soporte.urlPath)">
              <i class="pi pi-eye text-blue-600"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      No hay soportes
    </section>
    <template #footer>
      <Button label="Agregar Soporte" class="p-button-warning" @click="addSoporte"/>
      <Button label="OK" @click="closeModalIncidentesAdjuntos" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Agregar soportes" v-model:visible="displayRespuestasIncidenteAgregarAdjuntos"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeAgregarSoportes" autofocus/>
    </template>
  </Dialog>
</template>

<script>
import storeIncidencias from '../../../../../../store/incidencia.store'
import { computed, onMounted, ref, watch } from 'vue'
import FilePond from '../../../../../uploadFilePoundNew/index.vue'
import filepondStore from '../../../../../../store/filepond.store'
import Swal from 'sweetalert2'
import { useRouter, useRoute } from 'vue-router'
import dayjs from 'dayjs'
import PdfService from '../../../../../../services/pdf.service'
import TiposIncidenciasService from '../../../../../../services/tipoIncidencias.service'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(duration)
dayjs.extend(relativeTime)
export default {
  name: 'TablaIncidencias',
  components: {
    FilePond
  },
  setup () {
    const incidentes = computed(() => {
      return storeIncidencias.getters.incidencias
    })
    const respuestas = ref([])
    const idIncidencia = ref(null)
    const idRespuesta = ref(null)
    const pond = ref()
    const adjunto = computed(() => filepondStore.getters.adjunto)
    const adjuntos = ref([])
    const soportes = ref([])
    const paths = ref([])
    const router = useRouter()
    const route = useRoute()
    const tipos = ref([])
    const title = ref('')
    const displayMaximizable = ref(false)
    const displayRespuestasIncidente = ref(false)
    const displayRespuestasIncidenteAdjuntos = ref(false)
    const displayRespuestasIncidenteAgregarAdjuntos = ref(false)
    const soportesDisplay = ref(false)
    const displayIncidente = ref(false)
    const displayVerCierreIncidente = ref(false)
    const displaySoportesVerCierre = ref(false)
    const dataCierre = ref({
      observacionCierre: '',
      fechaCierre: null
    })
    const _PdfService = new PdfService()
    const _TiposIncidenciasService = ref(new TiposIncidenciasService())
    const paginator = ref({
      offset: 0,
      page: 0,
      limit: 10
    })
    const archivos = computed(() => storeIncidencias.getters.incidencia)

    const filters1 = ref({
      // tipo: {
      //   value: 1,
      //   matchMode: FilterMatchMode.EQUALS
      // }
      // base: {
      //   value: route.params.id,
      //   matchMode: FilterMatchMode.EQUALS
      // }
    })

    const clearFilter1 = () => {
      filters1.value = {
        // tipo: {
        //   value: 1,
        //   matchMode: FilterMatchMode.EQUALS
        // }
        // base: {
        //   value: route.params.id,
        //   matchMode: FilterMatchMode.EQUALS
        // }
      }
    }

    const onEditar = (data) => {
      storeIncidencias.commit('setDefault')
      router.push({
        name: 'pharmasan.administrativa.juridica.tutelas.incidentes.editar',
        params: { id: data.id },
        query: { idTutela: route.params.id }
      })
    }

    const onPage = ({ first, page, pageCount, rows }) => {
      paginator.value.offset = first
      paginator.value.page = page
      storeIncidencias.dispatch('getIncidentesTutelas', route.params.id, {
        paginator: paginator.value,
        filters: filters1.value
      })
    }

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const listarTiposIncidencias = () => {
      _TiposIncidenciasService.value.listar().then(({ data }) => {
        tipos.value = data.rows
      })
    }

    const tipo = ({ tipoincidencia }) => {
      console.log(tipoincidencia)
      if (tipoincidencia === 1) {
        return 'Incidente tipo Apertura Formal'
      } else {
        return 'Incidente tipo requerimiento'
      }
    }

    const openMaximizable = (id) => {
      displayMaximizable.value = true
      storeIncidencias.dispatch('findIncidencia', id)
    }

    const openSoportesMaximizable = () => {
      displayIncidente.value = false
      soportesDisplay.value = true
    }

    const openSoportesVerCierre = () => {
      displaySoportesVerCierre.value = true
      displayVerCierreIncidente.value = false
    }

    const closeMaximizable = () => {
      displayMaximizable.value = false
    }
    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina esta incidencia no se puede recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          storeIncidencias.dispatch('removeIncidencia', id).then(async () => {
            await storeIncidencias.dispatch('getPaginate', {
              paginator: paginator.value,
              filters: filters1.value
            })
            Swal.fire(
              'Eliminado!',
              'La incidencia fue eliminada con exito.',
              'success'
            )
          })
        }
      })
    }

    const modalPdf = (id) => {
      alert(`Falta agregar mdoal ${id}`)
    }

    watch(filters1, (a, b) => {
      paginator.value.offset = 0
      paginator.value.page = 0
      paginator.value.limit = 10
      storeIncidencias.dispatch('getIncidentesTutelas', route.params.id, {
        paginator: paginator.value,
        filters: filters1.value
      })
    })

    const onCerrarIncidente = ({ id }) => {
      idIncidencia.value = id
      displayIncidente.value = true
      adjuntos.value = []
      dataCierre.value = {
        observacionCierre: '',
        fechaCierre: null
      }
    }

    const cerrarIncidente = () => {
      displayIncidente.value = false
      if (!dataCierre.value.observacionCierre || !dataCierre.value.fechaCierre) {
        Swal.fire(
          'Advertencia',
          'El campo observaciones y fecha es obligatorio',
          'warning'
        ).then(() => {
          displayIncidente.value = true
        })
      } else {
        dataCierre.value.paths = adjuntos.value
        storeIncidencias.dispatch('updateIncidencia', {
          id: idIncidencia.value,
          payload: dataCierre.value
        }).then(({ data }) => {
          Swal.fire({
            title: 'Exito',
            text: 'Incidente registrado con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              dataCierre.value.observacionCierre = ''
              dataCierre.value.fechaCierre = null
              storeIncidencias.dispatch('getIncidentesTutelas', route.params.id, {
                paginator: paginator.value,
                filters: filters1.value
              })
            }
          })
        })
      }
    }

    const soportesCloseMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        adjuntos.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        soportesDisplay.value = false
        displayIncidente.value = true
        title.value = ''
      })
    }

    const closeSoportesVerCierre = () => {
      pond.value.onSubmitSupport().then(async () => {
        await _PdfService.create({
          tipoNombre: 'cierre_incidencias',
          tipo: 12,
          base: idIncidencia.value,
          fileName: title.value,
          urlPath: adjunto.value.urlPath
        })
        displaySoportesVerCierre.value = false
        title.value = ''
      })
    }

    const eliminarPdf = (index) => {
      adjuntos.value.splice(index, 1)
    }

    const openVerCierreIncidente = (id) => {
      idIncidencia.value = id
      storeIncidencias.dispatch('findCierre', id).then((data) => {
        dataCierre.value.observacionCierre = data.observacionCierre
        dataCierre.value.fechaCierre = dayjs(data.fechaCierre).format('YYYY-MM-DD')
        adjuntos.value = data.paths
      })
      displayVerCierreIncidente.value = true
    }

    const openRespuestasIncidentes = (data) => {
      idIncidencia.value = data.id
      storeIncidencias.dispatch('findRespuestasIncidentes', data.id).then((data) => {
        respuestas.value = data
        displayRespuestasIncidente.value = true
      })
    }

    const crearRespuesta = () => {
      router.push({
        name: 'pharmasan.administrativa.juridica.respuestas.crear',
        query: {
          tipo: 2,
          base: idIncidencia.value
        }
      })
    }

    const verRespuesta = ({ paths, id }) => {
      console.log(id)
      idRespuesta.value = id
      displayRespuestasIncidenteAdjuntos.value = true
      displayRespuestasIncidente.value = false
      soportes.value = paths
    }

    const closeModalIncidentesAdjuntos = () => {
      displayRespuestasIncidenteAdjuntos.value = false
      displayRespuestasIncidente.value = true
    }

    const addSoporte = () => {
      displayRespuestasIncidenteAdjuntos.value = false
      displayRespuestasIncidenteAgregarAdjuntos.value = true
    }

    const closeAgregarSoportes = () => {
      pond.value.onSubmitSupport().then(async () => {
        paths.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        await _PdfService.create({
          tipoNombre: 'respuestas',
          tipo: 4,
          base: idRespuesta.value,
          fileName: paths.value[0].fileName,
          urlPath: paths.value[0].urlPath
        })
        // await getFallos()
        // modalAgregarSoportesVisible.value = false
        displayMaximizable.value = false
        title.value = ''
      })
    }

    const indicadorEstadoColor = (fechaVencimiento, respuesta = false) => {
      const inicio = dayjs()
      const fin = dayjs(`${fechaVencimiento}`)
      if (respuesta) return 'respondida'
      if (fin.diff(inicio, 'day') >= 1) {
        return 'atiempo'
      } else if (fin.diff(inicio, 'day') < 1) {
        if (fin.diff(inicio, 'second') > 1) {
          return 'proximo-vencer'
        } else {
          return 'vencidos'
        }
      }
    }

    const indicadorEstadoNombre = (fechaVencimiento, respuesta = false) => {
      const inicio = dayjs()
      const fin = dayjs(`${fechaVencimiento}`)
      if (respuesta) return 'Respondida'
      if (fin.diff(inicio, 'day') >= 1) {
        return 'A tiempo'
      } else if (fin.diff(inicio, 'day') < 1) {
        if (fin.diff(inicio, 'second') > 1) {
          return 'A vencer'
        } else {
          return 'Vencido'
        }
      }
    }

    onMounted(() => {
      listarTiposIncidencias()
      storeIncidencias.dispatch('getIncidentesTutelas', route.params.id, {
        paginator: paginator.value,
        filters: filters1.value
      })
    })

    return {
      closeAgregarSoportes,
      addSoporte,
      closeModalIncidentesAdjuntos,
      verRespuesta,
      soportes,
      crearRespuesta,
      respuestas,
      displayRespuestasIncidente,
      displayRespuestasIncidenteAdjuntos,
      displayRespuestasIncidenteAgregarAdjuntos,
      openRespuestasIncidentes,
      closeSoportesVerCierre,
      displaySoportesVerCierre,
      displayVerCierreIncidente,
      indicadorEstadoNombre,
      openVerCierreIncidente,
      eliminarPdf,
      adjuntos,
      soportesCloseMaximizable,
      cerrarIncidente,
      title,
      soportesDisplay,
      incidentes,
      filters1,
      paginator,
      indicadorEstadoColor,
      clearFilter1,
      onPage,
      remove,
      onEditar,
      dayjs,
      verPdf,
      tipos,
      tipo,
      modalPdf,
      openMaximizable,
      closeMaximizable,
      displayMaximizable,
      archivos,
      displayIncidente,
      onCerrarIncidente,
      dataCierre,
      pond,
      openSoportesMaximizable,
      openSoportesVerCierre
    }
  }
}
</script>

<style scoped>
::v-deep(.p-dialog-mask .p-component-overlay ) {
  background-color: transparent !important;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

.respondida {
  background-color: #1149d7 !important;
  color: white;
}

.atiempo {
  background-color: #58AB30 !important;
  color: white;
}

.proximo-vencer {
  background-color: #FA901B !important;
}

.vencidos {
  background-color: #FA2311 !important;
}
</style>
