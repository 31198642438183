import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class IncidenciasService {
  paginate (params) {
    return http.get(`${baseUrl}/incidencias/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/incidencias/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  findCierre (id) {
    return http.get(`${baseUrl}/fallos/cierre/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  findRespuestasIncidentes (id) {
    return http.get(`${baseUrl}/fallos/glosas/respuestas/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  save (payload) {
    return http.post(`${baseUrl}/incidencias`, payload, {
      headers: {
        loading: true
      }
    })
  }

  edit (payload, id) {
    return http.put(`${baseUrl}/incidencias/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  remove (id) {
    return http.delete(`${baseUrl}/incidencias/${id}`, {
      headers: {
        loading: true
      }
     })
  }

  listarTipos (payload) {
    return http.get(`${baseUrl}/categorias/paginate`, payload, {
      headers: {
        loading: true
      }
    })
  }

  listarIncidentesTutelas (idTutela, payload) {
    return http.get(`${baseUrl}/incidencias/incidente-tutela/${idTutela}`, payload, {
      headers: {
        loading: true
      }
    })
  }
}
