import { createStore } from 'vuex'
import IncidenciasService from '../services/incidencia.service'
const _IncidenciasService = new IncidenciasService()

const storeIncidencias = {
  state: {
    _incidencias: [],
    _incidencia: {},
    _tipos: []
  },
  getters: {
    incidencias: state => state._incidencias,
    incidencia: state => state._incidencia,
    tipos: state => state._tipos
  },
  mutations: {
    setIncidencias (state, payload) {
      state._incidencias = payload
    },
    setIncidencia (state, payload) {
      state._incidencia = payload
    },
    setUpdateIncidencia (state, payload) {
      state._incidencia.observacion = payload.observacion
      state._incidencia.incidenteId = payload.incidenteId
      state._incidencia.tipo = payload.tipo
      state._incidencia.path = payload.path
      state._incidencia.fechaNotificacion = payload.fechaNotificacion
      state._incidencia.fechaVencimiento = payload.fechaVencimiento
    },
    setDefault (state) {
      state._incidencia = {}
    },
    setTipos (state, payload) {
      state._tipos = payload
    }
  },
  actions: {
    getPaginate ({ commit }, payload) {
      _IncidenciasService.paginate(payload).then(({ data }) => {
        commit('setIncidencias', data)
      })
    },
    createIncidencia ({ commit }, payload) {
        return _IncidenciasService.save(payload).then((response) => {
            return response
        })
    },
    findIncidencia ({ commit }, id) {
      return new Promise((resolve, reject) => {
        _IncidenciasService.find(id).then(({ data }) => {
          commit('setIncidencia', data)
          resolve(data)
        })
      })
    },
    findRespuestasIncidentes ({ commit }, id) {
      return _IncidenciasService.findRespuestasIncidentes(id).then(({ data }) => {
        return data
      })
    },
    findCierre ({ commit }, id) {
      return _IncidenciasService.findCierre(id).then(({ data }) => {
        return data
      })
    },
    updateIncidencia ({ getters }, { id, payload }) {
      return new Promise((resolve, reject) => {
        _IncidenciasService.edit(payload, id).then(({ data }) => {
          resolve(data)
        })
      })
    },
    removeIncidencia ({ commit }, id) {
      return _IncidenciasService.remove(id).then((response) => {
        return response
      })
    },
    getTiposIncidencia ({ commit }, payload) {
      return _IncidenciasService.listarTipos(payload).then(({ data }) => {
        commit('setTipos', data)
        return data
      })
    },
    getIncidentesTutelas ({ commit }, idTutela, payload) {
      _IncidenciasService.listarIncidentesTutelas(idTutela, payload).then(({ data }) => {
        commit('setIncidencias', data)
      })
    }
  }
}
const store = createStore(storeIncidencias)
export default {
  namespaced: true,
  ...store
}
