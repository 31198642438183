import axios from 'axios'
import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class PdfService {
  ver (params) {
    const url = `${baseUrl}/pdf/ver`
    const res = axios.getUri({
      url,
      params
    })
    console.log({ res })
    window.open(res, '_blank')
  }

  create (payload) {
    return http.post(`${baseUrl}/pdf`, payload, {
      headers: {
        loading: true
      }
    })
  }
}
